.bi-tr td.table-row-sticky.MuiTableCell-root.MuiTableCell-body {
  position: sticky;
  width: 6em;
  left: 0;
  -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
}

.bi-tr td.header-bg.MuiTableCell-root.MuiTableCell-body {
  background-color: #ff9800;
  color: white;
  font-weight: 500;
  overflow: auto;
}

.bi-tr td.sticky-content.MuiTableCell-root.MuiTableCell-body {
  background-color: white;
  // color: white;
  font-weight: 500;
  overflow: auto;
}

.bi-tr td.sticky-content.MuiTableCell-root.MuiTableCell-body {
  background-color: white;
  // color: white;
  font-weight: 500;
  overflow: auto;
}

.bi-tr2 td.header-bg.MuiTableCell-root.MuiTableCell-body {
  background-color: #203daf;
  color: white;
  font-weight: 500;
  overflow: auto;
}

.bi-tr th.MuiTableCell-root.MuiTableCell-body {
  position: absolute;
  width: 5em;
  -webkit-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  border-bottom: none;
  background-color: #ffffff;
}

.bi-table td:nth-child(odd) {
  white-space: nowrap;
}

.bi-table td:nth-child(even) {
  white-space: nowrap;
}

.bi-table tr:nth-child(odd) {
  background-color: #faf2e8;
  overflow: auto;
}

.bi-table tr:nth-child(even) {
  background-color: #ffffff;
  overflow: auto;
}

.bi-tr th:nth-child(even) {
  background-color: #faf2e8;
}

td.MuiTableCell-root {
  border-bottom: none;
}

.bi-analytics-wrapper {
  .view-more {
    text-align: right;
  }
  .MuiCardHeader-root {
    padding: 10px;
    background: #faf2e8;
    text-align: center;
  }
  .MuiTypography-displayBlock {
    line-height: 1.1;
  }
  .MuiCardContent-root {
    padding: 8px 12px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 8px;
  }
  .MuiCard-root {
    margin-bottom: 4px;
  }
}
