.new-order-services {
  display: block;
  margin: auto;
  .count {
    padding: 0px 4px;
  }
  .plus-btn {
    width: 15px;
    height: 15px;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    min-width: 15px;
    color: white;
    background-color: #ff9800;

    margin-right: 6px;
  }
  .minus-btn {
    width: 15px;
    height: 15px;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    min-width: 15px;
    margin-left: 6px;
    color: white;
    background-color: #c2c2c2;
  }
  .MuiIconButton-root.Mui-disabled {
    color: white;
    background-color: #c2c2c2;
  }
  .MuiIconButton-colorPrimary:hover {
    background-color: #ff9800;
  }
}

.fixed-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 599px) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .MuiButton-root {
    // background: #ff9800;
    border-radius: 30px;
  }
  .footer-btn {
    margin: 0 auto;
    align-items: center;

    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    // @media screen and (min-width: 600px) {
    //   justify-content: flex-end;
    //   .MuiButtonBase-root {
    //     margin-left: 18px;
    //   }
    // }
  }
}
