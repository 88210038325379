.roomtype-calendar {
  .empty-wrapper {
    height: 30px;
  }
  .date-days {
    height: 25px;
  }
  .room-type-option {
    // .MuiButton-root {
    //   width: 100%;
    //   padding: 0px;
    // }
    // .MuiButton-label {
    //   display: block;
    // }
    .room-type {
      border-radius: 0px;
      height: 20px;
      .MuiTypography-displayBlock {
        padding-left: 4px;
      }
    }
    .room-type.MuiListItem-root {
      width: 100%;
      max-width: 100%;
      margin-bottom: 4px;
    }
    .room-type.MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      border: 1px solid #2408ba;
      background-color: white;
    }
    .m-b-4 {
      margin-bottom: 4px;
    }
  }
  .calendar-column {
    text-align: center;

    .data-value {
      height: 20px;
      display: grid;
      align-items: center;
      overflow: hidden;
    }
  }
}

.circle-text {
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.custom-purple {
  color: #7a6ead;
}