.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
  
  .expand-card .MuiExpansionPanelDetails-root {
    display: inherit;
  }
  
  .autocomplete-list {
    padding: 0px;
    margin: 0px;
    box-shadow: 0 0 black;
  }
  