.popup-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .popup-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .popup-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .popup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }