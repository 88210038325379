.date-range-picker-wrapper {
  .DayPickerNavigation__horizontal {
    display: flex;
    width: 100%;
    justify-content: space-around;
    top: 20px;
  }
  .CalendarMonth_caption {
    color: #212121;
    font-size: 16px;
  }
  .CalendarDay__default {
    border: none;
  }
  .DayPickerNavigation_button__horizontalDefault {
    padding: 4px;
    border-radius: 6px !important;
    background: linear-gradient(320deg, #f2f3f6 0%, #e5e6ec 100%);
  }
  .DayPickerNavigation_button__default {
    border: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #2f5cff;
    border: none;
  }
  .CalendarDay__selected_span {
    background: #78aeff;
    border: none;
  }
}

.date-range-picker-input {
  border: 0;
  margin: 12px 0px;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  .input-label {
    color: #878787;
    padding: 0;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1;
  }
  .input-value {
    color: #212121;
    cursor: text;
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    border-bottom: 1px solid #949494;
    .value {
      font-size: 14px;
      padding: 6px 0 7px;
    }
  }
}
