.room-status-btn {
  border: solid 3px transparent !important;
  border-radius: 3px !important;
  height: 80%;
  .MuiListItemText-root {
    color: #ffffff;
  }
  .MuiTypography-colorTextSecondary {
    color: #ffffff;
  }
}

.room-status {
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    .MuiTypography-colorTextSecondary {
      color: white;
    }
    color: white;
    border: 3px solid #000000 !important;
    border-radius: 10px  !important;
  }
}

.room-status-btn.MuiListItem-root {
  text-align: center;
  
}
