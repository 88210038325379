.legend-circle {
  width: 15px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
  margin-right: 8px;
}

.circle.MuiIconButton-root {
  //   width: 36px;
  //   height: 36px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  margin: 0px 8.5px;
  //   //   position: absolute;
  //   flex: 0 0 auto;
  //   overflow: visible;
  //   text-align: center;
  //   border-radius: 50%;
}

.rate-policy {
  .header {
    text-align: center;
    background-color: #ff9800;
    color: white !important;
  }
  .type {
    align-items: center;
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .number-amount {
    .MuiInputBase-input {
      padding: 2px 6px;
      text-align: right;
      font-size: 12px;
      border: 1.5px solid #ff9800;
      border-radius: 3px;
    }
  }
}

.color-picker {
  .MuiInputBase-input {
    width: 50px;
    height: 30px;
  }
}

.rate-amount {
  .MuiInputBase-input {
    padding: 2px 6px;
    text-align: right;
    font-size: 12px;
    border: 1.5px solid #ff9800;
    border-radius: 3px;
  }
}

.grey-Item {
  .expand-card .MuiExpansionPanelSummary-root {
    background-color: '#d3d3d3';
  }
}

.bi-date-range-container {
  background: #faf2e8;
  position: fixed;
  // width: 100%;
  display: flex;
  z-index: 100;
  padding: 8px 6px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 298px);
    // position: fixed !important;
    // z-index: 100;
    top: 157px;
  }

  .MuiFormControl-root {
    width: 50%;
    max-width: 50%;
  }
  .arrow {
    margin: auto;
  }

  .custom-date-picker {
    .MuiOutlinedInput-input {
      // max-width: 50%;
      padding: 12px 0px;
      font-size: 12px;
      padding-left: 10px;
    }
    .MuiIconButton-root {
      padding: 0px;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 0px;
    }
  }
}

.sales-opt {
  background: #ff9800;
  color: white;
  text-align: center;
}
.sales-opt-content {
  text-align: center;
}

//! Account Mapping

.mapping-textfield {
  .MuiOutlinedInput-input {
    padding: 4px 12px;
    // text-align: right;
    font-size: 12px;
    // border: 1.5px solid #ff9800;
    border-radius: 3px;
  }
}

.mapping-tr td.table-row-sticky.MuiTableCell-root.MuiTableCell-body {
  position: sticky;
  width: 20em;
  left: 0;
  font-size: 12px;
  -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
}
.mapping-tr th.MuiTableCell-root.MuiTableCell-body {
  position: absolute;
  width: 20em;
  font-size: 12px;

  -webkit-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  border-bottom: none;
  // background-color: #ffffff;
}

.mapping-tr td.header-bg.MuiTableCell-root.MuiTableCell-body {
  background-color: #ff9800;
  color: white;
  font-weight: 500;
}

.mapping-table tr:nth-child(odd) {
  background-color: #faf2e8;
  z-index: 1000;
}

.mapping-table tr:nth-child(even) {
  background-color: #ffffff;
  z-index: 1000;
}

.mapping-tr th:nth-child(even) {
  background-color: #faf2e8;
}

td.MuiTableCell-root {
  border-bottom: none;
}
.mapping-table .MuiTableCell-root {
  padding: 12px;
  font-size: 12px;
}
.title-row {
  color: transparent !important;
}
.title-row-blue {
  color: transparenteff;
}

.banner-section {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  //   display: flex;
  //   box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;

  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  height: 70px;
  text-align: center;
  margin: auto;
  padding: 10px 0px;
  img {
    display: block;
    margin: 0 auto;
    width: 80px;
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    .MuiButton-root {
      min-width: 126px;
    }
    @media screen and (min-width: 600px) {
      justify-content: flex-end !important;
    }
  }
}
