.core-list .MuiListItem-root {
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  background: #fff;
  margin-bottom: 4px;
  cursor: pointer;
  padding: 12px;
  flex-wrap: wrap;
}

html .b-b,
body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.search-header-booking {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  &.MuiList-root {
    padding: 0px;
  }
  .MuiListItemIcon-root {
    min-width: 36px;
  }
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 4px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    color: #212121;
    margin-right: -8px;
  }
  .MuiInputBase-root {
    width: 100%;

    .MuiInputBase-input {
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.search-filter-booking {
  display: flex;
  margin-bottom: 4px;
  .search-header-booking {
    width: 50%;
  }
  .filter-dropdown {
    flex: 1;
    padding-left: 8px;
    .search-header-booking {
      width: 100%;
    }
  }
}

.fixed-multiple-search-header {
  position: fixed !important;
  top: 120px;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 209px;
  }
}

.fixed-custom-search-header {
  position: fixed !important;
  top: 120px;
  z-index: 100;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 5px;

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    top: 209px;
  }
}

.search-custom-field {
  position: absolute;
  width: 100%;
  background: #fff;
  margin-top: -41px;
  &.hide {
    display: none;
  }
}
.close-custom-search {
  margin-top: -43px;
  &.hide {
    display: none;
  }
}

.fixed-searchAction-header {
  position: fixed !important;
  top: 65px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }
  @media screen and (max-width: 500px) {
    width: 82%;
    max-width: 350px;
  }
  @media screen and (max-width: 400px) {
    width: 82%;
    max-width: 300px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}
.fixed-length-header {
  position: fixed !important;
  top: 215px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
    top: 210px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 260px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}
.fixed-length1-header {
  position: fixed !important;
  top: 200px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
    top: 200px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 260px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}
.fixed-length2-header {
  position: fixed !important;
  top: 290px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
    top: 290px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 260px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}
.fixed-length3-header {
  position: fixed !important;
  top: 255px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
    top: 255px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 260px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}
.fixed-length4-header {
  position: fixed !important;
  top: 180px;
  width: 88%;
  max-width: 500px;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: 100%;
    max-width: 599px;
    top: 180px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    max-width: 570px;
  }
  @media screen and (max-width: 630px) {
    width: 100%;
    max-width: 550px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 260px;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    max-width: 240px;
  }
  @media screen and (max-width: 300px) {
    width: 100%;
    max-width: 200px;
  }
  @media screen and (max-width: 265px) {
    width: 100%;
    max-width: 190px;
  }
}

.with-multiple {
  margin-top: 100px;

  @media screen and (min-width: 600px) {
    margin-top: 155px !important;
  }
}

.search-filter-BI {
  display: flex;
  margin-top: 50px;

  .filter-search {
    flex: 1;
    width: 50%;
    height: 50%;
  }

  .filter-search-item {
    .MuiListItem-root {
      height: 56px;
    }
  }

  .filter-dropdown {
    flex: 1;
    padding-left: 8px;

    .search-header-BI {
      width: 100%;
    }
  }
}

.expand-card .MuiExpansionPanelSummary-root {
  // background: #faf2e8 !important;
}

.date-picker {
  min-width: 100px;
  margin-right: 0px;
  width: 142px;
  .MuiInputAdornment-positionStart {
    margin-right: 0px;
  }
  .MuiInputBase-input {
    font-size: 12px;
  }
}
.rm-padding {
  margin-top: 10px;
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
  box-shadow: none !important;
  .MuiListItem-root {
    padding: 0px;
  }
}

.card-listing {
  box-shadow: none !important;
  padding: 0px !important;
  .MuiList-padding {
    padding-bottom: 0px;
  }
}

.rm-padding-card {
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100% !important;
  display: flex;
}
//duein-popout color

.light-blue {
  background: #e9efff;
}
.bg-light-blue {
  background-color: #b4cef2;
}
.bg-orange {
  background-color: #ffb11f !important;
}

.bg-blue {
  background-color: #ff9800;
}
.bg-purple {
  background-color: #9372fd !important;
}
.bg-red {
  background-color: #f30854 !important;
}
.bg-dark-blue {
  background-color: #0b004f;
}
.bg-green {
  background-color: #b2fc74;
}
.flex {
  width: 100%;
  display: flex;
}

.menu {
  .MuiPopover-paper {
    min-width: 100px;
  }
  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .subheader-search {
    ul.MuiList-root.search-header.MuiList-padding {
      position: fixed;
      z-index: 1;
      display: block;
      width: 100%;
      top: 69px;
    }
  }
}

.overflow-txt {
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.makeStyles-mainContent {
  top: 70px !important;
}

.small-textfield {
  .MuiOutlinedInput-input {
    padding: 4px 8px;
    text-align: center;
    font-size: 12px;
    border: 1.5px solid #ff9800;
    border-radius: 3px;
    background: white;
  }
}

.custom-date-picker {
  .MuiOutlinedInput-input {
    padding: 12px 0px;
    font-size: 12px;
  }
  .MuiIconButton-root {
    padding: 6px;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
  }
}

.custom-date-picker-report {
  .MuiOutlinedInput-input {
    padding: 12px 0px;
    font-size: 12px;
    padding-left: 10px;
  }
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 10px;
  }
}
.reset-listitem {
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemIcon-root {
    min-width: 20px;
  }
  .MuiTypography-body1 {
    line-height: 1;
  }
  .MuiTypography-body2 {
    line-height: 1;
  }
}

.fixed-search-header {
  position: fixed !important;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 157px;
  }
}

.margin-auto {
  margin: auto;
}

.dialog-search {
  .MuiList-root {
    padding-top: 0px;
  }
  .MuiListItem-root {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    .MuiInputBase-input {
      padding-left: 12px;
    }
  }
}
.fixed-multiple-search-header {
  position: fixed !important;
  top: 120px;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 209px;
  }
}

.with-multiple {
  margin-top: 100px;

  @media screen and (min-width: 600px) {
    margin-top: 155px !important;
  }
}

.filter-header {
  background-color: #faf2e8;
  padding: 8px 6px;

  position: fixed !important;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: calc(100% - 300px);
    // position: fixed !important;
    // z-index: 100;
    top: 157px;
  }
  // .MuiFormControl-root {
  //   width: 45%;
  //   max-width: 45%;
  // }

  .MuiInputBase-root {
    background: white;
    padding: 4px;
    border-radius: 3px;
  }
}

.sm-textfield {
  .MuiOutlinedInput-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    // border: 1.5px solid #ff9800;
    border-radius: 3px;
  }
}

.display-flex {
  display: flex;
}

.date-icon {
  .MuiIconButton-root {
    padding: 4px;
  }
}
.footer-icon {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 599px) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  .MuiBottomNavigationAction-label {
    font-size: 10px;
    line-height: 12px;
    margin-top: 2px;
    white-space: nowrap;
    font-weight: 600;
    color: #212121;
    &.Mui-selected {
      font-size: 10px;
      color: #212121;
    }
  }
  .MuiBottomNavigationAction-root {
    min-width: 0 !important;
  }
}
.rm-padding-infinitescroll {
  margin: -8px -6px !important;
}

.textfield-label .MuiInputLabel-root {
  white-space: nowrap;
}
.rating-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  .MuiSvgIcon-root {
    font-size: 14px;
    line-height: 16px;
    margin-right: 0px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.color-disabled {
  color: #a6a6a6;
}
.accordion-card {
  margin-bottom: 4px;
  &.MuiAccordion-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    height: 100%;
    min-height: 48px;
    &.Mui-expanded {
      height: 100%;
      min-height: 48px;
    }
    .MuiAccordionSummary-content {
      margin: 10px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 14px;
      // color: #212121;
    }
  }
  .MuiAccordionDetails-root {
    padding: 10px;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
}

.creditDebit-header {
  top: 112px;
  width: 100%;
  @media screen and (min-width: 600px) {
    top: 200px;
    width: calc(100% - 288px);
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }
}
.creditDebit-detail-header {
  top: 123px;
  width: 100%;
  @media screen and (min-width: 600px) {
    top: 211px;
    width: calc(100% - 288px);
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }
}
.video-section {
  video {
    top: 0px;
    left: 0px;
    display: block;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .scan-border {
    @media screen and (min-width: 600px) {
      svg {
        width: 500px;
        height: 350px;
      }
    }
  }
}
.contact-input-new.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;
  width: 100%;
  .special-label {
    color: #878787 !important;
    background: transparent;
    // margin-left: -26px;
  }

  .form-control {
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: #878787 !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 0px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}
.contact-input-auth.react-tel-input {
  margin-bottom: 6px;
  margin-top: 16px;
  width: 100%;
  .special-label {
    color: #878787 !important;
    background: transparent;
    margin-left: -26px;
  }
  .form-control {
    border: 0px;
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: #ffffff !important;
    border-bottom: 1px solid #878787 !important;
    padding: 12px 0px 12px 50px;
    border-radius: unset;
  }

  .country-list {
    max-height: 110px !important;
  }

  .special-label {
    font-size: 12px;
  }

  .invalid-number-message {
    left: -4px;
    top: 47px;
    color: #d32f2f;
    font: 400 0.75rem/1.66 Poppins, sans-serif;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}


.with-hk {
  margin-top: 76px;
  @media screen and (min-width: 600px) {
    margin-top: 64px !important;
  }
}
.with-hk2 {
  margin-top: 110px;
  @media screen and (min-width: 600px) {
    margin-top: 100px !important;
  }
}

.dynamic-subheaderhk {
  width: 100%;
  display: flex;
  z-index: 1200;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  padding: 8px 12px;
  position: fixed !important;
  background: #faf2e8;
  &.full-width {
    left: 24px;
    width: calc(100% - 48px);
  }
  &.single {
    height: 36px;
  }
  &.multiple {
    height: 53px;
  }
  .avatar-content {
    width: 60px;
    padding-right: 8px;
    .MuiAvatar-root {
      width: 35px;
      height: 35px;
      text-align: center;
      margin: auto;
      margin-bottom: 4px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
        0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    }
  }
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 158px;
    right: 24px;
  }

  .dynamic-content {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ffffff;
    align-items: center;
    .right-btn {
      padding: 0px;
      margin-left: 8px;
      color: #212121;
      .MuiSvgIcon-root {
        font-size: 18px;
      }
    }
  }
}

.auditlog-search-header{
  position: fixed !important;
  z-index: 100;
  top: 208px;
  width: calc( 100% - 12px);
  @media screen and (min-width: 600px) {
    width: calc(100% - 300px);
    top: 297px;
  }
}