.list-label {
  width: 100%;
  display: flex;
  .color-status {
    width: 20px;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    margin-right: 8px;
  }
  .text-label {
    margin-right: 8px;
  }
}
.location-wrapper {
  width: 30px;
  border-radius: 4px;
  color: white;
  text-align: center;
  padding: 1px;
  background-color: #001088;
}
.location {
  max-width: 12.666667% !important;
  flex-basis: 12.666667% !important;
  height: 30px;
}
.location-block {
  max-width: 12.666667% !important;
  flex-basis: 12.666667% !important;
  height: 35px;
  .location-wrapper {
    width: 30px;
    border-radius: 4px;
    color: white;
    text-align: center;
    padding: 1px;
    background-color: #001088;
  }
}
.room-block {
  .MuiListItem-root {
    width: 100%;
    background-color: #b2fc74;
    max-width: 100%;
    justify-content: center;
    height: 30px;
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);

    padding: 4px;
  }
  .MuiListItem-dense {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .MuiListItemText-root {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiTypography-body2 {
    line-height: 0;
  }
  .MuiTypography-colorTextSecondary {
    text-align: right;
    color: #ff9800;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    color: white;
    background-color: #2408ba !important;
    .MuiTypography-colorTextSecondary {
      color: white;
    }
  }
}
.room-wrapper {
  border-left: 1px solid #c2c2c2;
  width: 100%;
  display: flex;
  text-align: center;
  padding: 0px 4px;

  .MuiListItem-root {
    text-align: center;
    width: 63%;
    padding-right: 4px;
    background-color: #b4cef2;
    max-width: 63%;
    justify-content: center;
    height: 30px;
    border-radius: 3px;
    margin-bottom: 4px;
  }
}
.room-wrapper.MuiGrid-grid-xs-10 {
  max-width: 87.333333% !important;
  flex-basis: 87.333333% !important;
}

.room-box {
  width: 100%;
  height: 30px;
  text-align: center;
  display: flex;
  border-radius: 3px;
  /* background-color: #b4cef2; */
  margin-bottom: 4px;
  .total-guest {
    background: #e4ecf6;
    border-radius: 3px;
    width: 37%;
    border: solid 1px #ffffff;
    margin: auto;
    vertical-align: middle;
    display: inline-block;
    padding: 8px 0px;
    .icon-text {
      padding: 8px 0px;
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    color: white;
    background-color: #2408ba !important;
  }
}
.payment {
  padding-right: 4px;
  .MuiListItem-root {
    box-shadow: none !important;
    padding: 6px !important;
  }
}

.e-signature-clear {
  flex: 0 0 auto;
  display: flex;
  /* padding: 8px; */
  align-items: center;
  justify-content: flex-end;
}

.checkout-time-btn {
  border: solid 1px #e2e2e2 !important;
  border-radius: 3px !important;

  .MuiTypography-colorTextSecondary {
    color: #454545;
  }
}
.checkout-time {
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    .MuiTypography-colorTextSecondary {
      color: white;
    }
    color: white;
    background-color: #ff9800 !important;
  }
}
.checkout-time-btn.MuiListItem-root {
  text-align: center;
}

.dropdown {
  .MuiList-root {
    position: unset;
  }
}

.nonguestfolio-summary {
  position: fixed;
  width: 100%;
  // right: 0;
  bottom: 53px;
  z-index: 100;
  background: #faf2e8;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    // top: 157px;
  }
}
.override-contentheader .card-form .MuiCardHeader-root {
  background: #fff !important;
  border-bottom: 1px solid #d4cccc;
  padding: 8px;
}
.override-contentheader .card-form .MuiCardContent-root:last-child {
  padding-bottom: 8px !important;
}
.override-content .card-form .MuiCardContent-root {
  border: 1px solid #d8d8d8;
  background: #dfdfdf;
}
.accordion-roomassign {
  width: 100%;
  margin-bottom: 4px;

  &.MuiAccordionPanel-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    min-height: 34px;
    &.Mui-expanded {
      min-height: unset;
    }
    .MuiAccordionSummary-content {
      margin: 6px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 18px;
      // color: #212121;
    }
  }

  .MuiAccordionDetails-root {
    background: #ededed !important;
    padding: 8px;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }

  .header-button {
    background-color: #ffffff;
    color: #ff9800;
    font-size: 12px;
    font-weight: 600;
    float: right;
    box-shadow: none;
    border: solid 1px #ff9800;
    margin-right: 4px !important;
    padding: 0px 7px 0px 4px;
    .MuiButton-startIcon {
      margin-right: 4px;
    }
  }
}
.override-roominglist-disabled .card-form .MuiCardContent-root:last-child {
  background-color: #dddddd;
  padding-bottom: 8px !important;
}

.override-roominglist .card-form .MuiCardContent-root:last-child {
  padding-bottom: 8px !important;
}
.override-roominglist .p-4:not(:first-child) {
  padding: 6px 0px;
}
.pointer {
  cursor: pointer;
}
.guest-list .MuiBadge-badge {
  padding: 0 4px;
}
.guest-list .MuiBadge-anchorOriginBottomRightRectangle {
  transform: scale(1) translate(-15%, 40%);
}

.guest-ic-card {
  width: 250px;
  height: 145px;
  border-radius: 10px;
  margin: auto;
  // background-color: #e2e2e2;
}

.accordion-billingschedule {
  width: 100%;
  margin-bottom: 4px;
  &.MuiAccordionPanel-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiIconButton-edgeEnd {
    margin-right: 5px !important;
  }

  .MuiAccordionSummary-root {
    padding: 0px;
    min-height: 34px;
    &.Mui-expanded {
      min-height: unset;
    }
    .MuiAccordionSummary-content {
      margin: 0px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      // margin-right: 18px;
      // color: #212121;
    }
  }

  .MuiAccordionDetails-root {
    margin: 0px;
    background: #fff !important;
    padding: 6px 0px;
    flex-wrap: wrap;
    border-bottom: 1px solid #e6e6e6;
  }

  // .header-button {
  //   background-color: #ffffff;
  //   color: #ff9800;
  //   font-size: 12px;
  //   font-weight: 600;
  //   float: right;
  //   box-shadow: none;
  //   border: solid 1px #ff9800;
  //   margin-right: 4px !important;
  //   padding: 0px 7px 0px 4px;
  //   .MuiButton-startIcon {
  //     margin-right: 4px;
  //   }
  // }
}
.accordion-billingschedule .MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}

// house limit
.override-cardcontent .card-form .MuiCardContent-root {
  padding: 0px;
}
.override-cardcontent .card-form .MuiCardContent-root .MuiFormControl-root {
  margin-bottom: 0px !important;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #ff9800;
  border-radius: 70%;
  display: inline-block;
  border: 5px solid #ffffff;
  box-shadow: 1px 1px 1px 1px #bebebe;
}

.dot-disable {
  height: 10px;
  width: 10px;
  background-color: #f8f8f8;
  border-radius: 70%;
  display: inline-block;
  border: 5px solid #ffffff;
  box-shadow: 1px 1px 1px 1px #bebebe;
}
