//! RATE POLICY V 2 STYLES
.color-season {
  width: 25px;
  height: 18px;
  border-radius: 4px;
  margin-right: 10px;
}
.color-input {
  display: flex;
  margin: auto;
  align-items: center;
}
.color-season-form {
  width: 50px;
  height: 25px;
  border-radius: 4px;
  margin-right: 10px;
}

//! CALENDAR
.season-calendar div span.MuiPickersCalendarHeader-dayLabel {
  color: #212121;
  margin: 0 8.5px;
}

.season-calendar {
  background-color: #fff;
  // margin: 8px 6px;
  border-radius: 3px;
  width: 100%;
  margin-top: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
}

.season-calendar
  div
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
  font-weight: 600;
  color: #212121;
}

.season-calendar div .MuiPickersCalendarHeader-iconButton {
  z-index: 1;
  background-color: transparent;
  color: #212121;
}

.season-calendar
  button
  span
  p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorInherit {
  font-size: 14px;
  font-weight: 500;
  // color: #ffffff;
}

.season-calendar div button.MuiPickersDay-day {
  // color: #ffffff;

  margin: 0 8.5px;
}

.season-calendar div button.MuiPickersDay-daySelected {
  color: #212121;
  font-weight: 700;
  background-color: transparent;
}

.season-calendar div.MuiPickersCalendarHeader-switchHeader {
  padding-top: 20px;
  margin-top: 0px;
}

.circle.MuiIconButton-root {
  border-radius: 50%;
  // width: 30px;
  height: 30x;
  // margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  // margin: 0px 6.5px;
}

.ratepolicy-dialog {
  margin: -8px -12px 0px !important;
  padding: 0px 12px;
  width: 100%;
  display: flex;
  background: #e9efff;
  .plus-icon {
    .MuiIconButton-root {
      padding: 0px;
      width: 20px;
      height: 20px;
      background: blue;
    }
    .MuiSvgIcon-root {
      color: white;
      width: 15px;
      height: 15px;
    }
  }
  .minus-icon {
    .MuiIconButton-root {
      padding: 0px;
      width: 20px;
      height: 20px;
      background: red;
    }
    .MuiSvgIcon-root {
      color: white;
      width: 15px;
      height: 15px;
    }
  }
}

.roomsales-calendar div span.MuiPickersCalendarHeader-dayLabel {
  color: #212121;
  margin: 0 8.5px;
}

.roomsales-calendar {
  background-color: #fff;
  // margin: 8px 6px;
  border-radius: 3px;
  width: 100%;
  margin-top: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
}

.roomsales-calendar div button.MuiPickersDay-day {
  // color: #ffffff;

  margin: 0 8.5px;
}

