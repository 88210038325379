.slider-container {
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;


}

.overflow-content {
  max-height: 350px;
  overflow-y: scroll;
}

.overflow-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.slider-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0px 0px 0px 0px;
  // top right bottm left
}

.pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; /* Set right to 0 to extend the element to the right edge */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  padding-bottom: 60px;
}

/* Media query for mobile devices (up to a certain width) */
@media (max-width: 768px) {
  .pagination {
    /* Center the element horizontally on mobile devices */
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Desktop styles (for screens wider than 768px) */
@media (min-width: 769px) {
  .pagination {
    /* Slightly offset to the left on desktop devices */
    left: 15%;
  }
}

.prev,
.next {
  background-color: orange;
  color: #fff;
  padding: 7.5px 15px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;

 
}

/* Position "Prev" and "Next" buttons */
.pagination-buttons {
  position: absolute;
  display: flex;
  align-items: center;

}
.page-numbers {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  padding: 0px 15px;
  z-index: 4;
}

.page {
  margin: 0 8px;
  text-align: center;
  cursor: pointer;
  background-color: orange;
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 50%;
  border: none;
  color: #fff;
  align-items: center;
  button:focus { outline-style: none; }
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  font-size: 16px;
}


.page.error {
  outline: 2px solid red;
}

.page.selected {
  transition: background-color 0.3s; /* Animation duration */
  background-color: #acacac; /* Initial background color */
  color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  border: none;
  
}

/* Define the animation properties when a page is selected */
// .page.selected:hover {
//   background-color: #0056b3; /* Hover background color */
//   /* Add any other animation properties you want */
// }

// .page:hover {
//   background-color: #a84000; /* Hover background color */
//   /* Add any other animation properties you want */
// }

.page:not(.selected):hover ,
.next:not(.selected):hover ,
.prev:not(.selected):hover {
  background-color: rgb(255, 0, 0); /* Hover background color */
  /* Add any other animation properties you want */
}