.outlined-textfield {
  .MuiOutlinedInput-input {
    padding: 8px 10px;
    font-size: 12px;
    // border: 1.5px solid #ff9800;
    border-radius: 3px;
  }
}
.textfield-left-wrapper {
  .MuiFormControl-root {
    padding-right: 6px !important;
    width: 100% !important;
    // @media screen and (min-width: 600px) {
    //   width: 100% !important;
    // }
  }
}
.textfield-right-wrapper {
  .MuiFormControl-root {
    padding-left: 6px !important;
    padding-right: 6px !important;

    width: 100% !important;
    // @media screen and (min-width: 600px) {
    //   width: 100% !important;
    // }
  }
}
.delete-icon.MuiAvatar-root {
  width: 22px;
  height: 22px;
  cursor: pointer;
  color: white;
  background: #ff123f;
  svg {
    width: 16px;
    height: 16px;
  }
}

.remove-underline {
  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }
}
