.date-picker-header {
  position: fixed !important;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  height: auto;
  padding: 6px 12px;
  background: #faf2e8;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 157px;
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }
  .date-picker-single-input.MuiFormControl-root {
    background: #fff;
    width: 100%;
    border-radius: 3px;
    .MuiInputBase-input {
      padding-left: 8px;
      font-size: 14px;
    }
  }
  .date-picker-input.MuiFormControl-root {
    background: #fff;
    width: 48%;
    border-radius: 3px;
    margin: auto;
    .MuiInputBase-input {
      padding-left: 8px;
      font-size: 14px;
    }
  }
  .date-picker-input.right-date {
    float: right;
  }

  .date-picker-input.MuiInputAdornment-positionEnd {
    .MuiIconButton-root {
      padding: 0px;
    }
  }
}
