.gl-export-row td.header-bg.MuiTableCell-root.MuiTableCell-body {
  background-color: #ff9800;
  color: white;
  font-weight: 500;
  padding: 12px !important;
}

.gl-export-row {
  .MuiTableCell-root {
    padding: 10px;
  }
}

.gl-margin {
  margin-top: 50px;
  @media screen and (min-width: 600px) {
    margin-top: 115px;
  }
}

.gl-margin-detail {
  margin-top: 60px;

  @media screen and (min-width: 600px) {
    margin-top: 125px;
  }
}
