.ct-timeline {
  .timeline-header {
    // padding: 8px 12px;

    background: #e2e2e2;
  }
  &.MuiTimeline-root {
    margin: 0px;
    padding: 0px;
    .MuiTimelineItem-missingOppositeContent:before {
      flex: 0;
      padding: 0px;
    }
    .MuiTimelineContent-root {
      overflow: hidden;
      padding: 6px 12px;
    }
    .MuiTimelineDot-root {
      position: relative;
      margin-top: 10px;
    }
    .MuiTimelineConnector-root {
      background: #ff9800;
      margin-top: -30px;
    }

    .MuiTimelineDot-defaultPrimary {
      background: #ff9800;
    }
    .MuiTimelineItem-root {
      min-height: 40px;
    }
  }
  .core-list {
    padding-bottom: 0px;
    .MuiListItem-root {
      box-shadow: unset;
      background: none;
      padding: 0px;
      margin-bottom: 12px;
    }
  }
}
.override-contact-content {
  //   margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
}
